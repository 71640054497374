<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree class="left-tree" v-loading="treeLoading" node-key="id" ref="myTree"
            :props="defaultTreeProps"
            :expand-on-click-node="false"
            :highlight-current="true"
            @node-click="treeNodeClick" default-expand-all>
            <span class="custom-tree-node" slot-scope="{ node, data }" @mouseenter="mouseEnter(data,node)" @mouseleave="mouseLeave(data,node)">
              <span>{{ node.label }}</span>
              &nbsp;
              <span>
                <el-button v-show="data.show" type="text" size="mini" >
                  删除
                </el-button>
              </span>
            </span>
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__monthlyschedule">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <el-input v-model="dataForm.scheduleUnitName" placeholder="形象工程名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
                <toolbar-button role="query" ></toolbar-button>
                <toolbar-button role="add" ></toolbar-button>
                <toolbar-button role="delete" ></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
            <el-table-column label="季度" width="100" header-align="center" align="center" fixed="left">
              <template v-slot="{row}">{{row.y + '年第' + row.m + '季度'}}</template>
            </el-table-column>
            <el-table-column prop="scheduleUnitName" label="形象单元" min-width="150" fixed="left" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="unitName" label="单位" width="100" align="center" fixed="left" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="num" label="计划工程量" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="amount" label="计划金额(元)" width="150" align="right" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="startDate" label="开始日期" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="endDate" label="结束日期" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" align="center" width="150">
              <template v-slot="{row}">
                <table-button role="update" ></table-button>
                <table-button role="delete" ></table-button>
                <table-button role="files" @click="uploadDrawerHandle(row.id, [], true)"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
          </el-pagination>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '',
        getDataListIsPage: true,
        deleteURL: '',
      },
      dataForm: {
        prjId: '',
        contractId: '',
        y: null,
        q: null,
        scheduleUnitName: '', // 形象单元 名称
      },
      myBatchAddVisible: false,
      lastSelectedTreeId: '',

      treeLoading: false,
      treeData: [],
      defaultTreeProps: {
        children: 'children',
        label: 'name'
      },
    }
  },
  methods: {
    contractChange (data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.refreshDataList()
    },

    refreshDataList () {
      this.dataForm.y = ''
      this.dataForm.q = ''
      this.loadTreeData()
    },

    loadTreeData () {
      this.$refs.myTree.store.nodesMap = []
      let mockData = [
        {
          id: 1,
          y: 2024,
          q: 1,
        },
        {
          id: 2,
          y: 2024,
          q: 2,
        },
        {
          id: 3,
          y: 2024,
          q: 3,
        },
        {
          id: 4,
          y: 2024,
          q: 4,
        }
      ]
      this.treeData = mockData
      let myTree = this.$refs.myTree
        for (let i = 0; i < mockData.length; i++) {
          let data = mockData[i]
          let y = data['y']
          let q = data['q']
          let yId = y
          let mId = y + q
          let yearNode = myTree.getNode(yId)
          if (!yearNode) {
            myTree.append({
              id: yId,
              name: y + "年",
              y: y,
              q: '',
              children: []
            }, null)
          }
          let monthNode = myTree.getNode(mId)
          if (!monthNode) {
            myTree.append({
              id: mId,
              name: '第' + q + '季度',
              y: y,
              q: q,
              children: []
            }, yId)
          }
        }
    },

    //  年月节点点击事件
    treeNodeClick (data,node,ele) {
      // 点击高亮节点则取消高亮
      if (this.lastSelectedTreeId == (data.id + this.dataForm.contractId)) {
        this.lastSelectedTreeId = ''
        this.dataForm.y = ''
        this.dataForm.q = ''
        this.$refs.myTree.setCurrentKey(null)
      } else {
        this.dataForm.y = data.y
        this.dataForm.q = data.q

        this.lastSelectedTreeId = data.id + this.dataForm.contractId
      }
      
    },

    mouseEnter (data,node) {
      // 叶子节点才显示
      if (node.isLeaf) {
        this.$set(data,'show',true)
      }
    },

    mouseLeave (data,node) {
      if (node.isLeaf) {
        this.$set(data,'show',false)
      }
    },

  }
}
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 180px);
  }
  ::v-deep .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }
</style>