<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__menu">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-button size="mini" v-if="$hasPermission('sys:menu:save')" @click="addChildNodeHandle({id:0})">新增根目录</el-button>
        </el-form-item>
      </el-form>
      <vxe-table
            :loading="dataListLoading"
            size="mini"
            border
            auto-resize
            show-overflow
            :height="vxeTblHeight"
            :row-config="{isHover: true, isCurrent: true, keyField: 'id'}"
            :column-config="{resizable: true}"
            :tree-config="treeConfig"
            :edit-config="{trigger: 'click', mode: 'cell'}"
            :data="dataList">
        <vxe-column field="name" width="260" title="名称" tree-node></vxe-column>
        <vxe-column width="100" title="类型" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.type === 0" size="small">模块/菜单</el-tag>
            <el-tag v-if="row.type === 1" size="small">按钮</el-tag>
            <el-tag v-if="row.type === 2" size="small">其他</el-tag>
            <el-tag v-if="row.type === 3" size="small" type="warning">子系统</el-tag>
          </template>
        </vxe-column>
        <vxe-column width="100" title="菜单是否可见" align="center">
          <template #default="{ row }">
            <el-tag v-if="row.visible === 0" size="small" type="danger">不可见</el-tag>
            <el-tag v-else size="small">可见</el-tag>
          </template>
        </vxe-column>
        <vxe-column field="url" title="路由地址"></vxe-column>
        <vxe-column field="permissions" title="权限标识"></vxe-column>
        <vxe-column width="80" title="图标" align="center">
          <template #default="{ row }">
            <svg class="icon-svg" aria-hidden="true"><use :xlink:href="`#${row.icon}`"></use></svg>
          </template>
        </vxe-column>
        <vxe-column width="180" title="操作" align="center">
          <template #default="{ row }">
            <el-button v-if="$hasPermission('sys:menu:save') && (row.type != 1)" type="text" size="small" @click="addChildNodeHandle(row)">新增下级</el-button>
            <el-button v-if="$hasPermission('sys:menu:update')" type="text" size="small" @click="addOrUpdateHandle(row.id)">{{ $t('update') }}</el-button>
            <el-button v-if="$hasPermission('sys:menu:delete')" type="text" size="small" @click="deleteHandle(row.id)">{{ $t('delete') }}</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './menu-add-or-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        createdIsNeed: true,
        getDataListURL: '/sys/menu/list',
        deleteURL: '/sys/menu'
      },
      treeConfig: {
        transform: false,
        rowField: 'id',
        parentField: 'pid',
        // line: true,
        lazy: true,
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus'
      },
      vxeTblHeight: 600,
    }
  },
  components: {
    AddOrUpdate
  },
  mounted () {
    this.$nextTick(() => {
      this.vxeTblHeight = window.innerHeight - 170
    })
  },
  methods:{
    // 新增子节点
    addChildNodeHandle(row) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.pid = row.id
        this.$refs.addOrUpdate.dataForm.visible = row.visible
        this.$refs.addOrUpdate.dataForm.parentName = row.name
        this.$refs.addOrUpdate.init()
      })
    }
  }
}
</script>
<style scoped lang='scss'>
/* 解决table列冻结（fixed）后，出现边框不显示问题*/
::v-deep .el-table__fixed-header-wrapper {
  .el-table__header {
    th:not(.is-hidden):last-child {
      right: -1px;
    }
  }
}

::v-deep .el-table__row {
  td:not(.is-hidden):last-child {
    right:-1px;
  }
}
.menu-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 160px);
}
</style>
