<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__risksource">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-select v-model="dataForm.level" placeholder="风险源级别" clearable>
            <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:risksource:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:risksource:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="title" label="作业活动" min-width="200" sortable="custom" align="left" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.title }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="accidents" label="可导致的事故" width="220" align="left" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="level" label="风险源级别" width="120" align="center" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-tag :key="row.id" v-if="row.level" :type="getLevelTagType(row.level)" effect="dark" style="width: 100%">{{ row.levelName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="hagard" label="危害因素" width="220" align="left" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="measure" label="控制措施" width="220" align="left" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="190">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:risksource:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:risksource:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, [], !$hasPermission('opm:risksource:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './risksource-add-or-update'
import ViewPage from './risksource-view'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/riskSource/page',
        getDataListIsPage: true,
        deleteURL: '/opm/riskSource',
      },
      dataForm: {
        contractId: '',
        level: ''
      },
      levelOptions: [{  // 风险源级别
        value: 1,
        label: '较小'
      }, {
        value: 2,
        label: '一般'
      }, {
        value: 3,
        label: '较大'
      }, {
        value: 4,
        label: '重大'
      }],
    }
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换
    contractChange(data) {
      this.dataList = []
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      // 组件传入了不同的参数，重写混入的方法
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.levelOptions = this.levelOptions
        this.$refs.addOrUpdate.init()
      })
    },
    getLevelTagType(levelId) {
      let tagType = ''
      if (levelId == 1) {
        tagType = 'info'
      } else if (levelId == 2) {
        tagType = ''
      } else if (levelId == 3) {
        tagType = 'warning'
      } else if (levelId == 4) {
        tagType = 'danger'
      }
      return tagType
    }
  }
}
</script>