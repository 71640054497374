<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__safetytraining">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.title" placeholder="培训主题" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('opm:safetytraining:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('opm:safetytraining:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" header-align="center" align="center" width="50"></el-table-column>
        <el-table-column prop="title" label="培训主题" align="left" sortable="custom" show-overflow-tooltip>
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{ row.title }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="trainingDate" label="培训日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="organizer" label="主办机构" align="left" sortable="custom" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="培训地点" align="left" sortable="custom" show-overflow-tooltip></el-table-column>
        <!--<el-table-column prop="contents" label="学习内容" width="200" align="left" sortable="custom" show-overflow-tooltip></el-table-column>-->
        <el-table-column :label="$t('handle')" align="center" width="190">
          <template v-slot="{row}">
            <table-button role="update" v-if="$hasPermission('opm:safetytraining:update')" @click="addOrUpdateHandle(row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('opm:safetytraining:delete')" @click="deleteHandle(row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(row.id, ['人员花名册'], !$hasPermission('opm:safetytraining:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          :current-page="page"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="limit"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="pageSizeChangeHandle"
          @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './safetytraining-add-or-update'
import ViewPage from './safetytraining-view'

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/safetyTraining/page',
        getDataListIsPage: true,
        deleteURL: '/opm/safetyTraining',
      },
      dataForm: {
        contractId: '',
        title: '',
      }
    }
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 标段切换
    contractChange(data) {
      this.dataList = []
      this.dataForm.contractId = data.contractId
      this.getDataList()
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      // 组件传入了不同的参数，重写混入的方法
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        this.$refs.addOrUpdate.dataForm.contractId = this.dataForm.contractId
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>